.custom-popup .maplibregl-popup-content {
    min-width: 75px;
    width: auto;
    height: auto;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: larger;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    pointer-events: none;
  }

  .maplibregl-ctrl-attrib-inner {
    white-space: nowrap; 
    overflow-x: auto; 
    text-overflow: clip;  
    max-width: 100%;  
    display: block; 
    width: fit-content; 
    max-width: 80vw;
  }  

  ::-webkit-scrollbar {
    height: 4px;             
    width: 4px;         
    border: 1px solid #d5d5d5;
  }
  
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: transparent;
  }

  :hover::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #eeeeee;
  }

  :hover::-webkit-scrollbar-thumb {
    background-color: darkgrey; /* Color when hovered */
  }
  
  .custom-popup .maplibregl-popup-tip {
    border-top-color: rgba(255, 255, 255, 0.8);
  }
  
  .popup-content {
    text-align: center;
  }
  
  .feature-name {
    font-weight: bold;
    margin: 0;
  }

  .summary-text {
    font-size: 0.9em;
    margin-bottom: 2px;
    color: rgb(88, 88, 88);
  }
  
  .divider {
    width: 100%;
    border: none;
    border-top: 1px solid #ccc;
    margin: 5px 0;
  }
  
  .feature-value {
    margin: 0;
  }
  
  .thick-divider {
    width: 100%;
    border: none;
    border-top: 2px solid #000;
    margin: 10px 0;
  }
  
  .metadata-section {
    margin-top: 5px;
    font-size: smaller;
    color: #666;
  }
  
  .metadata-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2px 0;
  }
  
  .metadata-key {
    font-weight: bold;
    margin-right: 10px;
    text-align: left;
  }
  
  .metadata-value {
    text-align: right;
    flex-grow: 1;
  }