@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@100;400&display=swap");

.navbar {
  height: 75px; /* Fixed height to ensure full height alignment */
  display: flex; /* Make sure the navbar is a flex container */
  align-items: center; /* Centre items vertically */
  justify-content: space-between; /* Space elements between the logo and content */
  z-index: 1001;
  box-shadow: 0px 3px 5px 0px gray;
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: #f8f9fa;
  --tfnPurple: #7317de;
  --standardFontFamily: "Hanken Grotesk", sans-serif;
}

.navbarContent {
  display: flex;
  align-items: center;
  height: 100%; /* Make sure the flex container is full height */
}

.nav-links {
  display: flex;
  align-items: center;
}

.NavButton,
.ActiveNavButton {
  color: #4b3e91;
  font-family: var(--standardFontFamily);
  font-size: clamp(12px, 1.2vw, 18px);
  text-decoration: none;
  width: 12%;
  max-width: 300px;
  text-align: center;
  height: 100%; /* Ensures NavButton takes the full height of the navbar */
  display: flex;
  align-items: center; /* Centre text vertically */
  justify-content: center; /* Centre text horizontally */
  border-bottom-right-radius: 20px;
  transition: background-color 0.3s;
  position: relative; /* For positioning adjustments if needed */
}

.NavButton:hover,
.ActiveNavButton:hover {
  background-color: var(--tfnPurple);
  color: white;
}

.ActiveNavButton {
  background-color: var(--tfnPurple);
  color: white;
}

.empty-blank-nav {
  width: 100%;
  height: 75px;
}

.Button {
  display: none;
}

.sideNavbar-notShown {
  transition: left 0.5s cubic-bezier(0, 0, 0, 1.28);
  opacity: 0;
}

.sideNavbar-shown {
  transition: left 0.5s ease-out;
  overflow-y: auto;
}

.LatButton {
  display: none;
}

.ActiveLatButton {
  display: none;
}

.scrollable {
  display: none;
}

.notScrollable {
  display: none;
}

@media only screen and (max-width: 1165px) {
  .ActiveNavButton,
  .NavButton {
    font-size: large;
    border-bottom-right-radius: 20px;
  }
}

@media only screen and (max-width: 930px) {
  .NavButton,
  .ActiveNavButton {
    width: 10%;
  }
}

@media only screen and (max-width: 765px) {
  .NavButton,
  .ActiveNavButton {
    display: none;
  }
  .Button {
    display: block;
    position: absolute;
    left: 25px;
    cursor: pointer;
  }

  .sideNavbar-shown {
    width: 40%;
    height: 100vh;
    background-color: white;
    z-index: 1100;
    position: absolute;
    left: 0px;
    top: 75px;
    box-shadow: 1px 0px 20px 0px black;
  }

  .sideNavbar-notShown {
    width: 40%;
    height: 100vh;
    z-index: 1100;
    position: absolute;
    left: -765px;
    top: 75px;
  }

  .LatButton {
    color: black;
    font-family: var(--standardFontFamily);
    font-size: larger;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 1vh;
    margin: 3vh;
  }
  .ActiveLatButton {
    color: white;
    background-color: var(--tfnPurple);
    font-family: var(--standardFontFamily);
    font-size: larger;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 4vh 0;
  }

  .scrollable {
    padding-bottom: 100px;
    padding-right: 25px;
    display: flex;
    flex-direction: row-reverse;
  }

  .notScrollable {
    display: inherit;
    position: absolute;
    bottom: 100px;
    right: 25px;
  }
}

@media only screen and (max-width: 500px) {
  .sideNavbar-shown {
    width: 100%;
    box-shadow: none;
  }

  .sideNavbar-notShown {
    width: 100%;
  }
}
