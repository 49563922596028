.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000; /* Black background */
}

.login-box {
  background: #fff; /* White background */
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.login-logo {
  width: 150px;
  margin-bottom: 20px;
}

.login-box h2 {
  color: #333;
  margin-bottom: 10px;
}

.login-box p {
  color: #666;
  margin-bottom: 20px;
}

.login-box form {
  display: flex;
  flex-direction: column;
}

.login-box label {
  color: #333;
  margin-bottom: 5px;
  text-align: left;
}

.login-box input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-box .forgot-password {
  color: #0066cc;
  text-align: right;
  margin-bottom: 20px;
  text-decoration: none;
}

.login-box .forgot-password:hover {
  text-decoration: underline;
}

.login-box button {
  background-color: #6200ea;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-box button:hover {
  background-color: #4500b5;
}